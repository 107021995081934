<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <client-view-client-info-card :user-data="userData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <client-view-client-plan-card />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <client-view-client-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <client-view-client-permissions-card />
        </b-col>
      </b-row>

      <invoice-list />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/kassas/kassas-list/KassasList.vue'
import clientStoreModule from '../productStoreModule'
import ClientViewClientInfoCard from './ClientViewClientInfoCard.vue'
import ClientViewClientPlanCard from './ClientViewClientPlanCard.vue'
import ClientViewClientTimelineCard from './ClientViewClientTimelineCard.vue'
import ClientViewClientPermissionsCard from './ClientViewClientPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ClientViewClientInfoCard,
    ClientViewClientPlanCard,
    ClientViewClientTimelineCard,
    ClientViewClientPermissionsCard,

    InvoiceList,
  },
  setup() {
    const userData = ref(null)

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-product/fetchProduct', { id: router.currentRoute.params.id })
      .then(response => {
        const [data] = response.data.records
        userData.value = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
